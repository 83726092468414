<template>
    <b-field>
        <template #label>
            <div class="flex is-align-items-center">
                <div class="mr-2">
                    {{ $t('common.threshold.label') }}
                </div>
                <LTooltip
                    :label="$t(`common.threshold.tooltip.threshold`)"
                    position="is-top"
                    type="is-dark">
                    <b-icon
                        class="mt-1"
                        icon="info-circle"
                        pack="uil"
                        size="is-small">
                    </b-icon>
                </LTooltip>
            </div>
        </template>

        <Select
            :value="value"
            :disabled="disabled"
            :get-data="getThreshold"
            :searchable="false"
            :clearable="false"
            :placeholder="$t(`common.threshold.label`)"
            @input="$emit('input', $event)">
            <template #text="{ option: { name } }">
                {{ name }}%
            </template>
            <template #selected-option="{ option: { name } }">
                {{ name }}%
            </template>
        </Select>
    </b-field>
</template>

<script>
  import Select from "@/components/Common/Select/Select.vue";

  export default {
    name: "ThresholdSelect",
    components: { Select },

    props: {
      value: {
        type: Number,
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      getThreshold () {
        const items = Array.from({ length: 5 }, (e, i) => 10 * (i + 1));
        return { items, count: items.length };
      }
    }
  };
</script>

<style scoped>
</style>
